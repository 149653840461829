import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Image,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAppSelector } from "../store/hooks.ts";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import logoImage from "../assets/iconBlack.png";
import defaultProfilePic from "../assets/profileAnonPicInverse.png";
import { sizing } from "../styles/sizing";
import lightbulb from "../assets/lightbulb.svg";
import { getStatsigClient } from "../utils/statsig";
import { AutoResizeTextarea } from "./AutoResizeTextarea";
import { Link } from "react-router-dom";

const AskDetails = () => {
  const { askId } = useParams();
  const [askData, setAskData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [previousFeedback, setPreviousFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFeedback, setHasFeedback] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const updateMessageSeenStatus = async (
      askId: string,
      receiverUserId: string
    ) => {
      const seenAt = new Date().toISOString(); // Current timestamp
      try {
        const response = await axios.post(`/api/messages/seen`, {
          receiverUserId,
          askId: askId,
          seenAt,
          seenStatus: true,
        });
        if (response.status !== 200) {
          throw new Error("Failed to update message seen status");
        }
      } catch (error) {
        console.error("Error updating message seen status:", error);
      }
    };

    const fetchAskDetails = async () => {
      try {
        const response = await axios.get("/api/getQuestion", {
          params: { askId },
        });
        console.log("ask details response", response.data);
        setAskData(response.data);
        if (askId) {
          updateMessageSeenStatus(askId, response.data.receiverUserId);
        }
        if (response.data?.feedbackText) {
          setHasFeedback(true);
          setFeedback(response.data.feedbackText);
          setPreviousFeedback(response.data.feedbackText);
        }
      } catch (error) {
        console.error("Error fetching ask details:", error);
        // Handle error appropriately
        navigate("/"); // Redirect to home if ask not found
      } finally {
        setIsLoading(false);
      }
    };

    if (askId) {
      fetchAskDetails();
    }
  }, [askId]);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    setIsSubmitting(true);

    // Concatenate the original question and feedback
    const fullFeedbackContent = `Question: ${askData?.askText}  My Feedback: ${feedback}`;

    // Construct the new note object for feedback
    const feedbackNote = {
      userId: authState.userId,
      relatedAskId: askId,
      content: fullFeedbackContent, // Updated content with question and feedback
      embedding: null,
      embeddingModel: null,
      tokenCount: null,
      tokenCost: 0,
      status: "ENABLED",
      isArchived: false,
      createdAt: new Date().toISOString(),
      noteType: "FEEDBACK",
      updatedAt: new Date().toISOString(),
    };

    try {
      await axios.post(`/api/feedback/${askId}`, { feedback });
      // Submit the note to your API using axios
      const noteResponse = await axios.post("/api/notes", feedbackNote);
      if (noteResponse.status !== 200) {
        throw new Error("Failed to create note");
      }
      getStatsigClient().logEvent("feedback_added", feedback);
      if(askData?.isNuxQuestion) {
        getStatsigClient().logEvent("submit_feedback_to_nux_question", feedback);
      } else if(askData?.isExternalQuestion) {
        getStatsigClient().logEvent('submit_feedback_to_external_question', feedback)
      }

      setHasFeedback(true);
      setPreviousFeedback(feedback);
      toast({
        title: "Feedback submitted",
        description: "Thank you for your feedback!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Error",
        description: "Failed to submit feedback. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <Box textAlign="center" mt={8}>
          <Progress size="xs" isIndeterminate colorScheme="pink" />
        </Box>
      </>
    );
  }

  return (
    <Flex bg="#1C1C1C" minH="100vh">
      <Box
        maxW={sizing.maxAppWidthPx}
        mx="auto"
        p={4}
        textAlign="left"
        width="full"
        position="relative"
      >
        <Flex align="center" justify="space-between" mb={8}>
          <Button
            leftIcon={<ChevronLeftIcon boxSize={5} left={0} right={0} p={0} />}
            backgroundColor="#1C1C1C"
            color="white"
            borderRadius="md"
            _hover={{ bg: "#2C2C2C" }}
            size="md"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {askData?.askerLink && (
            <Flex
              as={Link}
              to={askData.askerLink}
              align="center"
              gap={2}
              cursor="pointer"
              transition="opacity 0.2s"
              borderRadius="30px"
              border="0.25px solid #545454"
              _hover={{ bg: "#2C2C2C" }}
              p={3}
              _focus={{
                outline: "none",
                boxShadow: "none",
              }}
            >
              <Box
                borderRadius="full"
                width={{ base: "30px", md: "30px", lg: "33px" }}
                height={{ base: "30px", md: "30px", lg: "33px" }}
                overflow="hidden"
                display="flex"
                alignItems="center"
              >
                <Image
                  src={askData?.askerProfilePic || defaultProfilePic}
                  alt="Asker Profile"
                  width="100%"
                  height="100%"
                  objectFit="cover"
                />
              </Box>
              <Text
                fontSize={{ base: "sm", md: "sm" }}
                fontWeight="bold"
                color="white"
              >
                Ask {askData?.askerDisplayName || "Anonymous"}
              </Text>
            </Flex>
          )}
        </Flex>

        {/* Question Box */}
        <Flex
          alignItems="flex-start"
          gap={4}
          mb={2}
          bg="#1c1c1c"
          borderRadius="45px"
          borderColor="#545454"
          borderWidth="0.25px"
          p={4}
          cursor="pointer"
          onClick={() => askData?.askerLink && navigate(askData.askerLink)}
          _hover={{ bg: "#2C2C2C" }}
        >
          <Image
            src={askData?.askerProfilePic || defaultProfilePic}
            alt={`${askData?.askerDisplayName || "Anonymous"}'s profile picture`}
            borderRadius="full"
            objectFit="cover"
            boxSize="52px"
            flexShrink={0}
            onError={(e) => {
              console.error("Image failed to load:", e);
              const img = e.target as HTMLImageElement;
              console.log("Attempted src:", img.src);
            }}
          />
          <Box flex={1}>
            {/* Username and "asked:" */}
            <Flex alignItems="center" justifyContent="space-between" gap={1}>
              <Flex alignItems="center" gap={1}>
                <Text color="#FF0050" fontWeight="bold" fontSize="small">
                  {askData?.askerDisplayName || "Anonymous"}
                </Text>
                <Text color="white" fontSize="small">
                  asked:
                </Text>
              </Flex>
            </Flex>

            <Text
              color="white"
              fontWeight="bold"
              textAlign="left"
              fontSize="md"
              minHeight="3rem"
            >
              {askData?.askText}
            </Text>
          </Box>
        </Flex>

        {/* Answer Box */}
        {askData?.answerText && (
          <Flex
            alignItems="flex-start"
            gap={4}
            mb={2}
            mt={4}
            bg="white"
            borderRadius="45px"
            p={4}
          >
            <Image
              src={logoImage}
              alt="Logo"
              borderRadius="full"
              objectFit="cover"
              boxSize="52px"
              flexShrink={0}
            />
            <Box flex={1}>
              <Flex alignItems="center" justifyContent="space-between" gap={1}>
                <Flex alignItems="center" gap={1}>
                  <Text color="#FF0050" fontWeight="bold" fontSize="small">
                    Your Dot
                  </Text>
                  <Text color="black" fontSize="small">
                    answered:
                  </Text>
                </Flex>
              </Flex>
              <Text
                color="black"
                fontWeight="bold"
                textAlign="left"
                fontSize="md"
                minHeight="3rem"
                wordBreak="break-word"
                overflowWrap="break-word"
                whiteSpace="pre-wrap"
              >
                {askData?.answerText}
              </Text>
            </Box>
          </Flex>
        )}

        {/* Feedback Box */}
        <Flex
          alignItems="flex-start"
          gap={4}
          mb={2}
          mt={4}
          bg="white"
          borderRadius="45px"
          p={4}
          _hover={{
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
            transform: "translateY(-1px)",
          }}
        >
          <Image
            src={authState.profilePic || defaultProfilePic}
            alt="Your profile picture"
            borderRadius="full"
            objectFit="cover"
            boxSize="52px"
            flexShrink={0}
          />
          <Box flex={1} position="relative">
            <AutoResizeTextarea
              value={feedback.slice(0, 2000)}
              onChange={handleFeedbackChange}
              onKeyDown={(e) => {
                if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
                  e.preventDefault();
                  handleSubmitFeedback();
                }
              }}
              maxLength={2000}
              minHeight="192px"
              width="100%"
              pb={10}
              px={0}
              pt={0}
              mt={2}
              fontSize="md"
              fontWeight="bold"
              color="black"
              bg="transparent"
              border="none"
              _focus={{
                outline: "none",
                boxShadow: "none",
              }}
              placeholder={
                askData?.answerText
                  ? "Add your feedback..."
                  : "Type your answer"
              }
            />
            <Flex
              position="absolute"
              bottom={2}
              right={0}
              alignItems="center"
              gap={2}
              bg="white"
              px={2}
              py={1}
              borderRadius="full"
              zIndex={1}
            >
              <Text fontSize="sm" color="gray.500">
                {feedback.length}/2000
              </Text>
            </Flex>
          </Box>
        </Flex>

        <Flex
          align="center"
          justify="center"
          gap={2}
          marginTop={2}
          marginBottom="80px"
        >
          <Image src={lightbulb} alt="Lightbulb icon" />
          <Text color="whiteAlpha.800" fontSize="10px">
            Adding feedback helps improve your Dot
          </Text>
        </Flex>

        {!hasFeedback || feedback !== previousFeedback ? (
          // Feedback Button Section
          // <Box mt={4} alignItems="center" display="vertical" justifyContent="center" width="100%">
          <Box
            mt={4}
            position="fixed"
            bottom="0"
            left="0"
            right="0"
            bg="#1c1c1c"
            p={4}
            textAlign="center"
            width="100%"
            maxWidth="100vw"
          >
            <Button
              onClick={handleSubmitFeedback}
              isLoading={isSubmitting}
              isDisabled={!feedback.trim()}
              width={`calc(100% - ${sizing.horizontalGuttersPx * 2}px)`}
              maxWidth={sizing.maxAppContentWidthPx}
              height="60px"
              bg="#FF0050"
              color="white"
              borderRadius="full"
              _hover={{ bg: "#FF3371" }}
            >
              Add Feedback
            </Button>
          </Box>
        ) : (
          // Feedback Submitted Message
          <Box mt={8} textAlign="center">
            <Text color="white" fontSize="2xl" fontWeight="bold" mb={4}>
              Feedback Submitted!
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default AskDetails;
