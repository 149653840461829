import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Input, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks.ts";
import InboxItem from "./InboxItem.tsx";
import { getStatsigClient } from "../utils/statsig.ts";
import OnboardingProgressIndicator from "./OnboardingProgressIndicator.tsx";
import { setAuthInfo } from "../store/slices/authSlice.ts";
import { sizing } from "../styles/sizing.ts";

const HomeTab = () => {
  const authState = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const userId = authState.userId; // Get userId from authentication state
  const [messageCards, setMessageCards] = useState([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCards, setFilteredCards] = useState([]);
  // Fetch message cards from the database
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Ensure we have a valid userId before fetching
        if (!userId) {
          console.error("No userId found");
          return;
        }
        // Use axios with Firebase authentication
        try {
          const response = await axios.get("/api/questions");

          if (response.status === 200) {
            const questions = response.data;
            return questions.map((question) => ({
              createdAt: question.createdAt,
              id: question.askId.toString(),
              content: question.askText,
              response: question.answerText,
              askerUserId: question.askerUserId,
              askerName: question.askerDisplayName,
              askerProfilePic: question.askerProfilePic,
              seenStatus: question.seenStatus,
              feedbackSubmitted: question.feedbackSubmitted,
              isNuxQuestion: question.isNuxQuestion,
            }));
          }
        } catch (error) {
          console.error("Error fetching questions:", error);
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    const fetchNotes = async () => {
      try {
        console.log("Sending request to server");
        getStatsigClient()?.logEvent("start_fetching_notes");
        const response = await axios.get(`/api/notes`);
        getStatsigClient()?.logEvent("end_fetching_notes");

        console.log("Received response from server");
        if (response.status !== 200) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }

        return response.data.map((note) => ({
          createdAt: note.createdAt,
          id: note.noteId,
          askerName: "You",
          askerProfilePic: authState.profilePic,
          content: note.content,
          seenStatus: true,
          isNote: true,
          isNuxQuestion: false,
          askerUserId: authState.userId
        }));
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };

    if (userId) {
      setIsLoading(true);
      Promise.all([fetchQuestions(), fetchNotes()]).then(
        ([questions, notes]) => {
          const allItems = [...questions, ...notes].sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          setMessageCards(allItems);
          setIsLoading(false);
        }
      );
    }
  }, [userId]);

  useEffect(() => {
    if (messageCards.length > 0) {
      const filteredItems = messageCards.filter((item) =>
        item.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCards(filteredItems);
    }
  }, [searchTerm, messageCards]);

  const handleAddNoteClick = async () => {
    getStatsigClient().logEvent("clicked_add_note");
    navigate("/addNote");
  };

  useEffect(() => {
    const numNotesOrQuestionsAnswered = messageCards.filter(
      (item) => item.isNote || item.feedbackSubmitted
    ).length;
    if (
      numNotesOrQuestionsAnswered >= 5 &&
      !authState.isOnBoardingTutorialComplete
    ) {
      // update updateIsOnBoardingTutorialComplete status
      Promise.all([axios.put(`/api/user/updateIsOnBoardingTutorialComplete`)]);

      console.log("Updating onboarding tutorial complete status");
      dispatch(
        setAuthInfo({
          isOnBoardingTutorialComplete: true,
          currentTabIndex: 2, // go to profile tab
        })
      );
    }
    dispatch(setAuthInfo({ numNotesOrQuestionsAnswered }));
  }, [messageCards]);

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Flex
        direction="column"
        alignItems="center"
        gap={4}
        mb="100px"
        width="100%"
        maxW="100%"
        mx="auto"
        overflowY="auto"
        overflowX="hidden"
        flex="1"
      >
        {!authState.isOnBoardingTutorialComplete && (
          <OnboardingProgressIndicator
            currentStep={authState.numNotesOrQuestionsAnswered}
          />
        )}
        {authState.isOnBoardingTutorialComplete && (
          // Search bar
          <Input
            type="text"
            placeholder="Search your notes"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            flex="1"
            backgroundColor="#262626"
            color="white"
            minHeight="3rem"
            fontSize="md"
            outline="none"
            borderRadius="full"
            border="0.25px solid"
            borderColor="gray.400"
            _placeholder={{ color: "gray.400" }}
            maxW="calc(100% - 16px)"
            _hover={{
              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
              transform: "translateY(1px)",
            }}
            _focus={{
              outline: "none",
              boxShadow: "none",
              borderColor: "gray.400",
            }}
            focusBorderColor="gray.400"
            sx={{
              "&:focus": {
                outline: "none !important",
                boxShadow: "none !important",
                borderColor: "gray.400 !important",
              },
            }}
          />
        )}
        {isLoading ? (
          <Center h="50vh">
            <Spinner
              thickness="4px"
              speed="0.75s"
              emptyColor="#1c1c1c"
              color="#FF0050"
              size="md"
            />
          </Center>
        ) : (
          filteredCards.map((card) => (
            <InboxItem
              key={card.id}
              askerName={card.askerName}
              askerProfilePic={card.askerProfilePic}
              title={card.content}
              isViewed={card.seenStatus}
              isReplied={card.feedbackSubmitted}
              isNote={card.isNote}
              id={card.id}
              isNuxQuestion={card.isNuxQuestion}
              askerId={card.askerUserId}
            />
          ))
        )}
      </Flex>
      <Box
        position="fixed"
        bottom="0"
        left="0"
        right="0"
        bg="#1c1c1c"
        p={4}
        textAlign="center"
        width="100%"
        maxWidth="100vw"
      >
        <Button
          width="100%"
          maxW={sizing.maxAppContentWidthPx}
          mx="auto"
          onClick={handleAddNoteClick}
          backgroundColor="#FF0050"
          color="white"
          height={{ base: "56px", md: "58px", lg: "60px" }}
          borderRadius="30px"
          _hover={{ bg: "#ef004b" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          Add a Note
        </Button>
      </Box>
    </div>
  );
};

export default HomeTab;
