import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks.ts";
import { useStatsigClient } from "@statsig/react-bindings";
import logo from "../assets/logo.png";

// Import your assets
import landingPagePhoneSvg from "../assets/landingPage.gif";
import axios from "axios";
import { sizing } from "../styles/sizing";

const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { client: statsigClient } = useStatsigClient();
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.isAuthenticated) {
      navigate("/");
    }
  }, [authState.isAuthenticated, navigate]);

  const handleSignIn = async () => {
    if (!email) {
      toast({
        title: "Please enter an email address.",
        status: "warning",
        duration: 3000,
      });
      return;
    }
    statsigClient.logEvent("click_continue_on_landing_page", undefined, { email });

    setIsLoading(true);
    try {
      await axios.post("/api/auth/sendSignInLink", { email });
      Cookies.set("emailForSignIn", email, { expires: 1 });
      setIsLoading(false);
      onOpen();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      // Handle errors
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleSignIn();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onEmailChange = (event) => {
    if(!email) {
      statsigClient.logEvent("event_landing_start_typing_email");
    }
    setEmail(event.target.value);
  };

  return (
    <Flex direction="column" minHeight="100vh" backgroundColor="#000000">
      {/* Header */}
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        py={6}
        px={{ base: 4, md: 12, lg: 24 }}
      >
        <Image
          src={logo}
          alt="Dots Logo"
          height={{ base: "32px", md: "40px", lg: "48px" }}
          objectFit="contain"
        />
      </Flex>

      {/* Main Content */}
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        flex="1"
        py={0}
        px={{ base: 4, md: 12, lg: 24 }}
        mb={0}
        mt={0}
        backgroundColor="#000000"
        gap={`clamp(15px, 5vw, 28px)`}
        maxW="auto"
        mx="auto"
      >
        <Box
          width={{ base: "100%", md: "100%", lg: "100%" }}
          mb={{ base: 8, md: 0 }}
          order={{ base: 2, md: 1, lg: 1 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxW={{ md: `${sizing.maxAppWidthPx}px` }}
          mr={{ md: 8, lg: 20 }}
        >
          <Image
            src={landingPagePhoneSvg}
            alt="Landing Page Visual"
            width={{ base: "75vw", md: "auto" }}
            height={{ base: "auto", md: "auto" }}
            maxHeight="75vh"
            minHeight={{ base: "auto", md: "auto", lg: "75vh" }}
            objectFit="contain"
          />
        </Box>
        <Box
          width={{ base: "100%", md: "100%", lg: "100%" }}
          mb={{ base: 8, md: 12 }}
          order={{ base: 1, md: 2, lg: 2 }}
          // maxW={{ md: "1000px" }}
        >
          <VStack align="center" spacing={4} width="100%">
            <Text
              color="white"
              fontSize={{ base: "35px", md: "40px", lg: "50px" }}
              fontWeight="bold"
              textAlign="center"
              lineHeight="shorter"
              mt={{ base: 0, md: 0, lg: -12 }}
              mb={{ base: 4, md: 4 }}
            >
              Stop wasting time
              <br></br>
              on bad dates
              {/* <br />
              in seconds */}
            </Text>

            <VStack align="start" spacing={4} width="100%" maxW="470px" mb={8}>
              <Flex align="center" width="100%">
                <Flex
                  w="32px"
                  h="32px"
                  borderRadius="full"
                  bg="#FF0050"
                  justify="center"
                  align="center"
                  mr={3}
                  flexShrink={0}
                >
                  <Text color="white" fontSize="lg" fontWeight="bold">1</Text>
                </Flex>
                <Text color="white" fontSize={{ base: "16px", md: "16px", lg: "18px" }} textAlign="left">Set up your profile – it's free and easy</Text>
              </Flex>
              
              <Flex align="center" width="100%">
                <Flex
                  w="32px"
                  h="32px"
                  borderRadius="full"
                  bg="#FF0050"
                  justify="center"
                  align="center"
                  mr={3}
                  flexShrink={0}
                >
                  <Text color="white" fontSize="lg" fontWeight="bold">2</Text>
                </Flex>
                <Text color="white" fontSize={{ base: "16px", md: "16px", lg: "18px" }} textAlign="left">Share your personal link on your apps</Text>
              </Flex>
              
              <Flex align="center" width="100%">
                <Flex
                  w="32px"
                  h="32px"
                  borderRadius="full"
                  bg="#FF0050"
                  justify="center"
                  align="center"
                  mr={3}
                  flexShrink={0}
                >
                  <Text color="white" fontSize="lg" fontWeight="bold">3</Text>
                </Flex>
                <Text color="white" fontSize={{ base: "16px", md: "16px", lg: "18px" }} textAlign="left">Show off the real you - get better matches!</Text>
              </Flex>
            </VStack>

            <Input
              type="email"
              placeholder="Enter your email to log in or sign up"
              value={email}
              onChange={onEmailChange}
              bg="white"
              color="black"
              py={7}
              pl={6}
              width="100%"
              minW={{ base: "300px", md: "400px", lg: "500px" }}
              maxW={{ base: "500px", md: "500px", lg: "550px" }}
              size="lg"
              height={{ base: "65px", md: "78px", lg: "78px" }}
              fontSize={{ base: "14px", md: "16px", lg: "18px" }}
              borderRadius="30px"
              onKeyDown={handleKeyDown}
              _focus={{
                outline: "none",
                boxShadow: "none",
              }}
            />
            <Button
              bg="#FF0050"
              color="white"
              onClick={handleSignIn}
              py={7}
              width="100%"
              minW={{ base: "300px", md: "400px", lg: "500px" }}
              maxW={{ base: "500px", md: "500px", lg: "550px" }}
              size="lg"
              height={{ base: "65px", md: "78px", lg: "78px" }}
              fontSize={{ base: "18px", md: "20px", lg: "20px" }}
              _hover={{ bg: "#F7004E" }}
              borderRadius="30px"
              isLoading={isLoading}
              spinner={
                <Spinner
                  thickness="4px"
                  speed="0.75s"
                  emptyColor="#1c1c1c"
                  color="#FF0050"
                  size="md"
                />
              }
              _focus={{
                outline: "none",
                boxShadow: "none",
              }}
            >
              Continue
            </Button>
          </VStack>
        </Box>
      </Flex>

      {/* Back to Top Link (only for mobile) */}
      <Flex
        justify="center"
        w="full"
        pt={5}
        pb={12}
        display={{ base: "flex", md: "none" }}
      >
        <Link color="white" onClick={scrollToTop} textDecoration="underline">
          Back to top ↑
        </Link>
      </Flex>

      {/* Footer */}
      <Flex
        justify="center"
        w="full"
        pb={4}
        gap={2}
        color="#545454"
        fontSize="10px"
        fontWeight="bold"
      >
        <Link
          href="https://docs.google.com/document/d/1p3-fJ25CafAI13qyOYEZMu4rCH95JVaXRfsNejTyDD0/edit?usp=sharing"
          _hover={{ textDecoration: "underline" }}
        >
          Terms of Service
        </Link>
        <Text>•</Text>
        <Link
          href="https://docs.google.com/document/d/1zImTFVA7nfo3-ZcbCk6Hr1Y67W5P9GHEhi8PPaUAxdc/edit?usp=sharing"
          _hover={{ textDecoration: "underline" }}
        >
          Privacy Policy
        </Link>
      </Flex>

      {/* Success Modal */}
      <Modal
        isOpen={isOpen}
        isCentered
        onClose={onClose}
        closeOnOverlayClick={true}
        closeOnEsc={true}
        focus={{
          outline: "none",
          boxShadow: "none",
        }}
      >
        <ModalOverlay bg="blackAlpha.900" />
        <ModalContent
          bgColor="#1c1c1c"
          opacity="1"
          py="30px"
          color="white"
          borderRadius="30px"
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          width="100%"
          maxWidth={sizing.maxAppContentWidthPx}  
        >
          <ModalHeader color="white" textAlign="center">
            Verification Link Sent!
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" align="center" justify="center" w="full">
              <img
                src="/assets/email_link_sent.svg"
                alt="Email Sent"
                width="50px"
                height="50px"
              />
              <Text textAlign="center" mt={6} mb={2}>
                A sign-in link was sent to {email}. <br /> Click it to sign-in
                to Dots!
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LandingPage;
